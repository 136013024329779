<template>
  <c-flex
    flex-dir="column"
    align-items="center"
  >
    <HeaderComp />

    <c-box
      :max-w="['inherit', '1200px']"
      w="100%"
      mx="auto"
      mt="60px"
      mb="130px"
    >
      <Breadcrumb
        :items="[
          { label: 'Beranda', href: '/' },
          { label: 'Seimbangkan Isi Piringmu', isCurrent: true },
        ]"
      />

      <c-flex
        justify-content="center"
        align-items="center"
        max-w="1200px"
        border-top="1px solid #F2F2F2"
        mt="30px"
      >
        <c-flex
          :mt="[null, '30px']"
          :ml="!isNutri ? [null, '52px'] : ''"
          :w="!isNutri ? ['100%', '850px'] : ''"
          :flex-grow="isNutri ? 1 : ''"
          flex-dir="column"
          align-items="center"
        >
          <c-box
            w="100%"
            align="center"
          >
            <c-heading
              font-size="36px"
              font-weight="bold"
              line-height="54px"
              mb="4px"
            >
              {{ item.title }}
            </c-heading>
          </c-box>

          <c-box
            w="794px"
            mt="63"
          >
            <c-flex
              v-if="item.picture"
              flex-dir="column"
              align-items="center"
              mb="41px"
            >
              <c-box
                w="100%"
                min-height="250px"
                border-radius="10px"
                overflow="hidden"
                bg="#C4C4C4"
              >
                <c-image
                  :src="item.picture.url"
                  :alt="item.picture.caption"
                />
              </c-box>
              <c-text
                v-if="item.picture.caption"
                align="justify"
                font-size="18px"
                line-height="27px"
                color="#555555"
                mt="26px"
                w="100%"
              >
                {{ item.picture.caption }}
              </c-text>
            </c-flex>
            <c-text
              align="justify"
              font-size="18px"
              line-height="27px"
              color="#555555"
              mb="63px"
              v-html="item.content"
            />
          </c-box>
        </c-flex>
      </c-flex>
    </c-box>
    <FooterComp />
  </c-flex>
</template>

<script>
import HeaderComp from '@/components/header/index.vue'
import FooterComp from '@/components/layouts/footer/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DetailBannerPage',
  components: {
    HeaderComp,
    FooterComp,
  },
  data() {
    return {
      item: '',
    }
  },
  computed: {
    ...mapGetters({
      axiosCMS: 'customAxiosCMS',
    }),
    isNutri() {
      return false
    },
  },
  async mounted() {
    this.loadDetailBanner()
  },
  methods: {
    loadDetailBanner() {
      let id = this.$route.params.id
      this.axiosCMS.get(`/banners/${id}`).then((res) => {
        this.item = res.data
      })
    },
  },
}
</script>
