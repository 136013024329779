var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "align-items": "center"
    }
  }, [_c('HeaderComp'), _c('c-box', {
    attrs: {
      "max-w": ['inherit', '1200px'],
      "w": "100%",
      "mx": "auto",
      "mt": "60px",
      "mb": "130px"
    }
  }, [_c('Breadcrumb', {
    attrs: {
      "items": [{
        label: 'Beranda',
        href: '/'
      }, {
        label: 'Seimbangkan Isi Piringmu',
        isCurrent: true
      }]
    }
  }), _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "max-w": "1200px",
      "border-top": "1px solid #F2F2F2",
      "mt": "30px"
    }
  }, [_c('c-flex', {
    attrs: {
      "mt": [null, '30px'],
      "ml": !_vm.isNutri ? [null, '52px'] : '',
      "w": !_vm.isNutri ? ['100%', '850px'] : '',
      "flex-grow": _vm.isNutri ? 1 : '',
      "flex-dir": "column",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "100%",
      "align": "center"
    }
  }, [_c('c-heading', {
    attrs: {
      "font-size": "36px",
      "font-weight": "bold",
      "line-height": "54px",
      "mb": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.title) + " ")])], 1), _c('c-box', {
    attrs: {
      "w": "794px",
      "mt": "63"
    }
  }, [_vm.item.picture ? _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "align-items": "center",
      "mb": "41px"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "100%",
      "min-height": "250px",
      "border-radius": "10px",
      "overflow": "hidden",
      "bg": "#C4C4C4"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.item.picture.url,
      "alt": _vm.item.picture.caption
    }
  })], 1), _vm.item.picture.caption ? _c('c-text', {
    attrs: {
      "align": "justify",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#555555",
      "mt": "26px",
      "w": "100%"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.picture.caption) + " ")]) : _vm._e()], 1) : _vm._e(), _c('c-text', {
    attrs: {
      "align": "justify",
      "font-size": "18px",
      "line-height": "27px",
      "color": "#555555",
      "mb": "63px"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.item.content)
    }
  })], 1)], 1)], 1)], 1), _c('FooterComp')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }